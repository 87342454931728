import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from '@mui/material';
import GoogleAd from './components/GoogleAd';

import { ChannelDetail, VideoDetail, SearchFeed, Navbar, Feed } from './components';

const App = () => (
  
  <BrowserRouter>
 
    <Box sx={{ backgroundColor: '#000' }}>
      <Navbar />
      
      <Routes>
        <Route exact path='/' element={<Feed />} />
        <Route path='/video/:id' element={<VideoDetail />} />
        <Route path='/channel/:id' element={<ChannelDetail />} />
        <Route path='/search/:searchTerm' element={<SearchFeed />} />
        
      </Routes>
    </Box>
    <GoogleAd slot="5101415721" googleAdId="ca-pub-8190542928955947"/>
  </BrowserRouter>
);

export default App;

