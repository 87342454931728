import React from "react";
import { Stack, Box } from "@mui/material";

import { ChannelCard, Loader, VideoCard } from "./";
import GoogleAd from '../components/GoogleAd';
const Videos = ({ videos, direction }) => {
  if(!videos?.length) return <Loader />;
  <GoogleAd slot="5101415721" googleAdId="ca-pub-8190542928955947"/>
  return (
    <Stack direction={direction || "row"} flexWrap="wrap" justifyContent="start" alignItems="start" gap={2}>
      {videos.map((item, idx) => (
        <Box key={idx}>
          {item.id.videoId && <VideoCard video={item} /> }
          {item.id.channelId && <ChannelCard channelDetail={item} />}
        </Box>
      ))}
    </Stack>
    
  );
}

export default Videos;
